<template>
  <NotMemberLayer>
    <ScoreTableCluster
      v-for="(cluster, clusterIdx) in clustersTable"
      :key="clusterIdx"
      :class="
        clusterIdx === clustersTable.length - 1
          ? 'mt-4'
          : clusterIdx === 0
          ? 'mb-4'
          : 'my-4'
      "
      :number="clusterIdx + 1"
      variant="answer"
      :editable="false"
      v-bind="cluster"
    />
  </NotMemberLayer>
</template>

<script>
import {
  ScoreTableCluster,
  NotMemberLayer,
} from '@/components/questionnaire/score'

export default {
  components: {
    ScoreTableCluster,
    NotMemberLayer,
  },
  props: {
    clustersTable: {
      type: Object,
      required: true,
    },
  },
}
</script>
