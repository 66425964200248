<template>
  <div class="relative h-full w-full flex">
    <div v-if="yAxisLabel" class="y-axis-label">{{ yAxisLabel }}</div>
    <div class="relative h-full w-full">
      <BarChart
        v-if="chartData.datasets?.length"
        :chartData="chartData"
        :options="options"
      />
    </div>
  </div>
</template>
<script>
import {
  Chart,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  //   LogarithmicScale,
  //   RadialLinearScale,
  //   TimeScale,
  //   TimeSeriesScale,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from 'chart.js'
Chart.register(
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  SubTitle
)
import { BarChart } from 'vue-chart-3'

export default {
  components: {
    BarChart,
  },
  props: {
    datasets: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    chartOptions: {
      type: Object,
      required: false,
    },
    yAxisLabel: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      defaultOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    }
  },
  computed: {
    options() {
      return {
        ...this.defaultOptions,
        ...this.chartOptions,
      }
    },
    maxValue() {
      const allValues = this.datasets.reduce((acc, dataset) => {
        dataset.data.forEach(entry => acc.push(entry))
        return acc
      }, [])
      return Math.max(...allValues)
    },
    chartData() {
      return {
        labels: this.labels.map(label => label.split('\n')),
        datasets: this.datasets.map(set => {
          set.backgroundColor =
            set.backgroundColor === 'primaryGradient'
              ? this.getGradient
              : set.backgroundColor
          return set
        }),
      }
    },
  },
  methods: {
    getGradient(context) {
      const chart = context.chart
      const { ctx, chartArea } = chart
      if (!chartArea) {
        // This case happens on initial chart load
        return '#22cd8f'
      }
      const percentageToMaxValue =
        this.maxValue > 0 ? context.raw / this.maxValue || 100 : 0
      const gradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.bottom - chartArea.height * percentageToMaxValue
      )
      gradient.addColorStop(0, '#22cd8f')
      gradient.addColorStop(1, '#11b3bd')

      return gradient
    },
  },
}
</script>

<style scoped>
.y-axis-label {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-align: center;
}
</style>
