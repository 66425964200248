<template>
  <div class="flex flex-col px-14" :class="requestPending && 'cursor-wait'">
    <div class="flex flex-col py-10">
      <span class="section-label">
        {{
          `${$t('assetManagement.objects-edit-data')}${
            canEditObjectData ? '*' : ''
          }`
        }}
      </span>
      <div class="input-grid mt-2">
        <Input
          v-model="editObjectInputs.name"
          :placeholder="$t('input.objectName')"
          :errorMessage="errorMessages.name"
          :disabled="!canEditObjectData"
          @blur="v$.editObjectInputs.name.$touch()"
        />
        <div
          class="col-start-1 col-end-3 grid grid-cols-2 gap-10"
          v-for="(assetType, i) in editObjectInputs.assetAssetTypes"
          :key="i"
        >
          <div>
            <Select
              v-if="canEditObjectData"
              :modelValue="findAssetClassOption(assetType.assetTypeId)"
              :items="assetClasses.options.value"
              @update:modelValue="value => updateSelectedOption(value, i)"
              :placeholder="$t('input.assetClass')"
              :info="$t('input.assetClass-info')"
              :errorMessage="errorMessages.assetAssetTypes?.[i]?.[0]"
            />
            <Input
              v-else
              :modelValue="
                assetClassName(
                  assetType?.assetClassIdentifier,
                  assetType?.assetClassIsPilot
                )
              "
              :placeholder="$t('input.assetClass')"
              :disabled="true"
            />
          </div>

          <div class="w-full flex justify-between">
            <Input
              class="w-full"
              type="number"
              v-model="assetType.percentage"
              :placeholder="$t('input.percentage')"
              :errorMessage="errorMessages.percentage"
              :disabled="!canEditObjectData"
              @focusout ="updateToInteger"
            />
            <Icon
              v-if="disableAddAssetType()"
              class="inline-block stroke-current w-6 lg:w-5 ml-7 lg:ml-5 text-darkGray mb-2"
              name="trash"
            />
            <Icon
              v-else
              class="inline-block stroke-current cursor-pointer w-6 lg:w-5 ml-7 lg:ml-5 text-[#a60a28] mb-2"
              name="trash"
              @click="deleteAssetType(assetType.assetTypeId)"
            />
          </div>
        </div>
        <div class="col-start-2 text-right">
          <Button
            v-if="canEditDetails"
            class="w-[200px] px-4 text-sm"
            variant="primary"
            @click="addAssetType"
            >{{ $t('general.more-class') }}
          </Button>
        </div>
        <Select
          v-if="canEditObjectData"
          v-model="groupSelectModel"
          :items="objectGroups.options.value"
          :placeholder="$t('input.objectGroup')"
          :errorMessage="errorMessages.assetGroupId"
          @close="v$.editObjectInputs.assetGroupId.$touch()"
        />
        <Input
          v-else
          :modelValue="object.assetGroup.name"
          :placeholder="$t('input.objectGroup')"
          :disabled="true"
        />
        <Input
          v-model="editObjectInputs.ownerCompany"
          :placeholder="$t('input.organisation')"
          :disabled="true"
        />
        <Input
          v-model="editObjectInputs.city"
          :placeholder="$t('input.city')"
          :errorMessage="errorMessages.city"
          :disabled="!canEditObjectData"
          @blur="v$.editObjectInputs.city.$touch()"
        />
        <Input
          v-model="editObjectInputs.street"
          :placeholder="$t('input.street')"
          :errorMessage="errorMessages.street"
          :disabled="!canEditObjectData"
          @blur="v$.editObjectInputs.street.$touch()"
        />
        <div class="flex justify-between">
          <Input
            class="flex-1"
            v-model="editObjectInputs.streetNumber"
            :placeholder="$t('input.streetNumber')"
            :errorMessage="errorMessages.streetNumber"
            :disabled="!canEditObjectData"
            @blur="v$.editObjectInputs.streetNumber.$touch()"
          />
          <Input
            class="flex-1 ml-10"
            v-model="editObjectInputs.zip"
            :placeholder="$t('input.zip')"
            :errorMessage="errorMessages.zip"
            :disabled="!canEditObjectData"
            @blur="v$.editObjectInputs.zip.$touch()"
          />
        </div>
        <Select
          v-if="canEditObjectData"
          v-model="countrySelectModel"
          :items="countries.options.value"
          :placeholder="$t('input.country')"
          :errorMessage="errorMessages.country"
          @blur="v$.editObjectInputs.country.$touch()"
        />
        <Input
          v-else
          :modelValue="object.country"
          :placeholder="$t('input.country')"
          :disabled="true"
        />

        <Select
          v-if="canEditObjectData"
          v-model="conditionsAndEnvironmentGoals"
          :items="conditionsAndEnvironmentGoalsOptions"
          :placeholder="$t('input.conditionsAndEnvironmentGoals')"
          :errorMessage="errorMessages.conditionsAndEnvironmentGoals"
          @blur="v$.editObjectInputs.conditionsAndEnvironmentGoals.$touch()"
          :info="$t('input.conditionsAndEnvironmentGoals-info')"
        />
        <Input
          v-else
          :modelValue="conditionsAndEnvironmentGoals.text"
          :placeholder="$t('input.conditionsAndEnvironmentGoals')"
          :disabled="true"
        />
      </div>
    </div>

    <div class="flex flex-col py-10">
      <span class="section-label">{{
        $t('assetManagement.objects-edit-details')
      }}</span>
      <div class="input-grid mt-2">
        <Datepicker
          v-model.number="constructionYearModel"
          :placeholder="$t('input.constructionYear')"
          :info="$t('input.constructionYear-info')"
          :disabled="!canEditDetails"
          :errorMessage="errorMessages.constructionYear"
          @blur="v$.constructionYearModel.$touch()"
          :minimum-view="'year'"
          :maximum-view="'year'"
        />
        <Datepicker
          v-model.number="renovationYearModel"
          :placeholder="$t('input.renovationYear')"
          :info="$t('input.renovationYear-info')"
          :disabled="!canEditDetails"
          :errorMessage="errorMessages.renovationYear"
          @blur="v$.renovationYearModel.$touch()"
          :minimum-view="'year'"
          :maximum-view="'year'"
        />
        <Select
          v-model="qualitySelectModel"
          :items="qualityOptions"
          :placeholder="$t('input.featureQuality')"
          :info="$t('input.featureQuality-info')"
          :infoImage="'feature-quality-' + $i18n.locale"
          :disabled="!canEditDetails"
        />
        <Input
          v-model.number="editObjectInputs.assetTotalArea"
          :placeholder="$t('input.assetTotalArea')"
          :info="$t('input.assetTotalArea-info')"
          :disabled="!canEditDetails"
          :errorMessage="errorMessages.assetTotalArea"
          @blur="v$.editObjectInputs.assetTotalArea.$touch()"
        />
        <Input
          v-model.number="editObjectInputs.usableArea"
          :placeholder="$t('input.assetUsableArea')"
          :info="$t('input.assetUsableArea-info')"
          :disabled="!canEditDetails"
          :errorMessage="errorMessages.usableArea"
          @blur="v$.editObjectInputs.usableArea.$touch()"
        />
        <Input
          v-model.number="editObjectInputs.rentingPeople"
          :placeholder="$t('input.numTenants')"
          :info="$t('input.numTenants-info')"
          :disabled="!canEditDetails"
          :errorMessage="errorMessages.rentingPeople"
          @blur="v$.editObjectInputs.rentingPeople.$touch()"
        />
        <Select
          v-model="historicProtectionSelectModel"
          :items="historicProtectionOptions"
          :placeholder="$t('input.historicProtection')"
          :info="$t('input.historicProtection-info')"
          :disabled="!canEditDetails"
        />
        <Input
          v-model.number="editObjectInputs.ipms2"
          :placeholder="$t('input.ipms2')"
          :info="$t('input.ipms2-info')"
          :disabled="!canEditDetails"
          :errorMessage="errorMessages.ipms2"
          @blur="v$.editObjectInputs.ipms2.$touch()"
        />
        <Input
          v-model.number="editObjectInputs.conditionedArea"
          :placeholder="$t('input.conditionedArea')"
          :info="$t('input.conditionedArea-info')"
          :errorMessage="errorMessages.conditionedArea"
          @blur="v$.editObjectInputs.conditionedArea.$touch()"
        />
        <Select
          v-model="projectCharacteristicsModel"
          :items="projectCharacteristicsOptions"
          :nullable="true"
          :placeholder="$t('input.projectCharacteristics')"
        />
        <Datepicker
          v-model="determinationStartDateModel"
          :placeholder="$t('input.determinationStartDate')"
          :info="$t('input.determinationStartDate-info')"
          :errorMessage="errorMessages.determinationStartDate"
          @blur="v$.determinationStartDateModel.$touch()"
        />
        <Datepicker
          v-model="acceptanceDateModel"
          :placeholder="$t('input.acceptanceDate')"
          :errorMessage="errorMessages.acceptanceDate"
          @blur="v$.acceptanceDateModel.$touch()"
        />
        <Datepicker
          v-model="handoverDateModel"
          :placeholder="$t('input.handoverDate')"
          :errorMessage="errorMessages.handoverDate"
          @blur="v$.handoverDateModel.$touch()"
          :info="$t('input.handoverDate-info')"
        />
        <Select
          v-model="projectStatusModel"
          :items="projectStatusOptions"
          :nullable="true"
          :placeholder="$t('input.projectStatus')"
        />
      </div>
      <span v-if="canEditDetails" class="text-[14px] text-darkGray">{{
        `*${$t('input.requiredFields')}`
      }}</span>

      <Button
        v-if="canEditDetails"
        class="self-end w-[240px] px-[88px] mt-6"
        :disabled="requestPending"
        variant="primary"
        @click="confirm"
        >{{ $t('general.save') }}</Button
      >
    </div>
  </div>
</template>

<script>
import { omit, pick, cloneDeep } from 'lodash-es'
import { mapActions } from 'vuex'
import useVuelidate from '@vuelidate/core'
import {
  EUserRole,
  EFeatureQuality,
  EHistoricProtection,
  EConditionsAndEnvironmentGoals,
  EprojectCharacteristics,
  EProjectStatus,
} from '@/enums'
import { user, object as objectHelpers, general } from '@/helpers'
import {
  useRequest,
  useValidators,
  useAssetClasses,
  useCountries,
  useObjectGroups,
} from '@/composition'
import { objects as objectsApi } from '@/services/api'
import { Input, Select, Button, Datepicker } from '@/components/form'
import Icon from '@/components/Icon'
import { required, numeric, minValue, maxValue, helpers } from '@vuelidate/validators'

const emptyObject = {
  name: '',
  assetGroupId: null,
  buildingName: '',
  ownerCompany: '',
  country: '',
  city: '',
  zip: '',
  street: '',
  streetNumber: '',
  constructionYear: 0,
  renovationYear: 0,
  quality: EFeatureQuality.Low,
  assetTotalArea: 0,
  usableArea: 0,
  rentingPeople: 0,
  historicProtection: EHistoricProtection.NoStatement,
  questionnaireId: null,
  ipms2: 0,
  conditionsAndEnvironmentGoals:
    EConditionsAndEnvironmentGoals.StockAndClimateProtection,
  conditionedArea: 0,
  projectCharacteristics: null,
  determinationStartDate: null,
  acceptanceDate: null,
  handoverDate: null,
  projectStatus: null,
  assetAssetTypes: [
    {
      assetTypeId: null,
      percentage: 100,
    },
  ],
}

const optionalFields = [
  'constructionYear',
  'renovationYear',
  'quality',
  'assetTotalArea',
  'usableArea',
  'rentingPeople',
  'historicProtection',
  'ipms2',
  'conditionedArea',
  'projectCharacteristics',
  'projectStatus',
  'determinationStartDate',
  'acceptanceDate',
  'handoverDate',
]

export default {
  components: {
    Input,
    Button,
    Select,
    Datepicker,
    Icon,
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
    disallowEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const assetClasses = useAssetClasses()
    const countries = useCountries()
    const objectGroups = useObjectGroups()
    const editRequest = useRequest(objectsApi.edit)
    const validators = useValidators()

    return {
      assetClasses: pick(assetClasses, ['request', 'pending', 'options']),
      countries: pick(countries, ['request', 'pending', 'options']),
      objectGroups: pick(objectGroups, ['request', 'pending', 'options']),
      editRequest,
      validators,
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      groupOptions: [],
      editObjectInputs: cloneDeep(this.object),
    }
  },
  validations() {
    const fieldValidations = Object.keys(
      omit(emptyObject, ['buildingName', 'questionnaireId'])
    ).reduce((result, field) => {
      if (!optionalFields.includes(field)) {
        result[field] = { required: this.validators.required }
      } else {
        result[field] = { required: false }
      }
      return result
    }, {})
    fieldValidations.assetTotalArea = {
      optionalNumeric: this.validators.optionalNumeric,
    }
    fieldValidations.usableArea = {
      optionalNumeric: this.validators.optionalNumeric,
    }
    fieldValidations.rentingPeople = {
      optionalNumeric: this.validators.optionalNumeric,
    }
    fieldValidations.ipms2 = {
      optionalNumeric: this.validators.optionalNumeric,
    }
    fieldValidations.conditionedArea = {
      optionalNumeric: this.validators.optionalNumeric,
    }
    return {
      editObjectInputs: {
        ...fieldValidations,
        assetAssetTypes: {
          $each: helpers.forEach({
            assetTypeId: { required },
            percentage: { required, numeric, minValue: minValue(1), maxValue: maxValue(100) },
          }),
        },
      },
    }
  },
  computed: {
    errorMessages() {
      const fields = Object.keys(
        omit(emptyObject, [
          'buildingName',
          'questionnaireId',
          'quality',
          'historicProtection',
          'handoverDate',
          'renovationYear',
          'acceptanceDate',
          'constructionYear',
          'determinationStartDate',
          'projectCharacteristics',
          'projectStatus',
        ])
      )
      return this.validators.errorMessages(this.v$.editObjectInputs, fields)
    },
    canEditDetails() {
      return (
        !this.disallowEdit &&
        (user.hasPermission([
          EUserRole.ObjectAdministrator,
          EUserRole.AccountAdministrator,
          EUserRole.ObjectDataCollector,
        ]) ||
          (user.hasPermission([EUserRole.ProspectiveBuyer]) &&
            !this.isReferenced))
      )
    },
    canEditObjectData() {
      return (
        !this.disallowEdit &&
        user.hasPermission([
          EUserRole.ObjectAdministrator,
          EUserRole.AccountAdministrator,
        ])
      )
    },
    groupSelectModel: {
      get() {
        return this.objectGroups.options.value.find(
          o => o.key === this.editObjectInputs.assetGroupId
        )
      },
      set({ key, org }) {
        this.editObjectInputs.assetGroupId = key
        this.editObjectInputs.ownerCompany = org
      },
    },
    countrySelectModel: {
      get() {
        return this.countries.options.value.find(
          o => o.key === this.editObjectInputs.country
        )
      },
      set({ key }) {
        this.editObjectInputs.country = key
      },
    },
    qualityOptions() {
      const tPrefix = 'assetManagement.objects-featureQuality'
      return [
        { key: EFeatureQuality.Low, text: this.$t(`${tPrefix}-low`) },
        { key: EFeatureQuality.Mid, text: this.$t(`${tPrefix}-mid`) },
        { key: EFeatureQuality.High, text: this.$t(`${tPrefix}-high`) },
      ]
    },
    qualitySelectModel: {
      get() {
        return this.qualityOptions.find(
          o => o.key === this.editObjectInputs.quality
        )
      },
      set({ key }) {
        this.editObjectInputs.quality = key
      },
    },
    historicProtectionOptions() {
      const tPrefix = 'assetManagement.objects-historicProtection'
      return [
        {
          key: EHistoricProtection.NoStatement,
          text: this.$t(`${tPrefix}-noStatement`),
        },
        { key: EHistoricProtection.None, text: this.$t(`${tPrefix}-none`) },
        { key: EHistoricProtection.Facade, text: this.$t(`${tPrefix}-facade`) },
        {
          key: EHistoricProtection.SelectedAreas,
          text: this.$t(`${tPrefix}-selectedAreas`),
        },
        {
          key: EHistoricProtection.FacadeAndSelectedAreas,
          text: this.$t(`${tPrefix}-facadeAndSelectedAreas`),
        },
      ]
    },
    historicProtectionSelectModel: {
      get() {
        return this.historicProtectionOptions.find(
          o => o.key === this.editObjectInputs.historicProtection
        )
      },
      set({ key }) {
        this.editObjectInputs.historicProtection = key
      },
    },
    conditionsAndEnvironmentGoalsOptions() {
      const tPrefix = 'assetManagement.objects-CEG'

      const options = Object.keys(EConditionsAndEnvironmentGoals)
        .filter(v => isNaN(v))
        .map(key => {
          return {
            key: EConditionsAndEnvironmentGoals[key],
            text: this.$t(`${tPrefix}-${key}`),
          }
        })
      return options
    },
    conditionsAndEnvironmentGoals: {
      get() {
        return this.conditionsAndEnvironmentGoalsOptions.find(
          o => o.key === this.editObjectInputs.conditionsAndEnvironmentGoals
        )
      },
      set({ key }) {
        this.editObjectInputs.conditionsAndEnvironmentGoals = key
      },
    },
    projectCharacteristicsOptions() {
      const tPrefix = 'assetManagement.objects-PC'

      let options = Object.keys(EprojectCharacteristics)
        .filter(v => isNaN(v))
        .map(key => {
          return {
            key: EprojectCharacteristics[key],
            text: this.$t(`${tPrefix}-${key}`),
          }
        })
      return options
    },
    projectCharacteristicsModel: {
      get() {
        return this.projectCharacteristicsOptions.find(
          o => o.key === this.editObjectInputs.projectCharacteristics
        )
      },
      set({ key }) {
        this.editObjectInputs.projectCharacteristics = key
      },
    },
    projectStatusOptions() {
      const tPrefix = 'assetManagement.objects-PS'
      const options = Object.keys(EProjectStatus)
        .filter(v => isNaN(v))
        .map(key => {
          return {
            key: EProjectStatus[key],
            text: this.$t(`${tPrefix}-${key}`),
          }
        })
      return options
    },
    projectStatusModel: {
      get() {
        return this.projectStatusOptions.find(
          o => o.key === this.editObjectInputs.projectStatus
        )
      },
      set({ key }) {
        this.editObjectInputs.projectStatus = key
      },
    },
    constructionYearModel: {
      get() {
        return this.editObjectInputs.constructionYear
          ? new Date(this.editObjectInputs.constructionYear, 0, 1)
          : null
      },
      set(val) {
        const date = new Date(val)
        const year = date.getFullYear()
        this.editObjectInputs.constructionYear = year
      },
    },
    renovationYearModel: {
      get() {
        return this.editObjectInputs.renovationYear
          ? new Date(this.editObjectInputs.renovationYear, 0, 1)
          : null
      },
      set(val) {
        const date = new Date(val)
        const year = date.getFullYear()
        this.editObjectInputs.renovationYear = year
      },
    },
    determinationStartDateModel: {
      get() {
        return this.editObjectInputs.determinationStartDate
          ? new Date(this.editObjectInputs.determinationStartDate)
          : null
      },
      set(val) {
        const date = general.formatWithTimezoneOffset(val)

        this.editObjectInputs.determinationStartDate = date
      },
    },
    acceptanceDateModel: {
      get() {
        return this.editObjectInputs.acceptanceDate
          ? new Date(this.editObjectInputs.acceptanceDate)
          : null
      },
      set(val) {
        const date = general.formatWithTimezoneOffset(val)

        this.editObjectInputs.acceptanceDate = date
      },
    },
    handoverDateModel: {
      get() {
        return this.editObjectInputs.handoverDate
          ? new Date(this.editObjectInputs.handoverDate)
          : null
      },
      set(val) {
        const date = general.formatWithTimezoneOffset(val)

        this.editObjectInputs.handoverDate = date
      },
    },
    requestPending() {
      return (
        this.assetClasses.pending.value ||
        this.countries.pending.value ||
        this.objectGroups.pending.value ||
        this.editRequest.pending.value
      )
    },
    isReferenced() {
      return (
        this.$store.getters['auth/organisationId'] !=
        this.object.assetGroup.organisationId
      )
    },
  },
  methods: {
    ...mapActions('toastMessage', ['showMessage']),
    assetClassName(assetClassIdentifier, assetClassIsPilot) {
      return objectHelpers.assetClassName(
        assetClassIdentifier,
        assetClassIsPilot
      )
    },
    async confirm() {
      this.v$.editObjectInputs.$touch()
      if (this.v$.editObjectInputs.$error) {
        return
      }

      if (this.editObjectInputs.assetAssetTypes?.map(x => x.percentage)?.reduce((partialSum, a) => partialSum + a, 0) != 100) {
        this.showMessage({
          type: 'error',
          translationKey: 'objectGroups-create-error-percentages',
        })
        return
      }

      const assetTypesIds = this.editObjectInputs.assetAssetTypes?.map(x => x.assetTypeId);
      if (assetTypesIds.filter((n, i) => assetTypesIds.indexOf(n) === i).length != assetTypesIds.length) {
        this.showMessage({
          type: 'error',
          translationKey: 'objectGroups-create-error-assettype-duplicate',
        })
        return
      }

      this.editRequest.error.value = false
      this.editRequest.success.value = false

      try {
        const payload = pick(this.editObjectInputs, Object.keys(emptyObject))
        payload.id = this.object.id
        await this.editRequest.request(payload)
        this.showMessage({
          type: 'success',
          translationKey: 'objects-edit-success',
        })
      } catch {
        this.showMessage({
          type: 'error',
          translationKey: 'objects-edit-error',
        })
      }
    },
    requestFormOptions() {
      if (this.canEditObjectData) {
        this.assetClasses.request()
        this.countries.request()
        this.objectGroups.request()
      }
    },
    addAssetType() {
      this.editObjectInputs.assetAssetTypes.push({
        assetTypeId: null,
        percentage: 0,
      })
    },
    findAssetClassOption(assetTypeId) {
      return this.assetClasses.options.value.find(
        option => option.key === assetTypeId
      )
    },
    updateSelectedOption(selectedOption, index) {
      this.editObjectInputs.assetAssetTypes[index].assetTypeId =
        selectedOption.key
    },
    deleteAssetType(assetTypeId) {
      const index = this.editObjectInputs.assetAssetTypes.findIndex(
        assetType => assetType.assetTypeId === assetTypeId
      )
      if (index !== -1) {
        this.editObjectInputs.assetAssetTypes.splice(index, 1)
      }
    },
    updateToInteger(e) {
      this.editObjectInputs.assetAssetTypes.forEach((aat) => {
        aat.percentage = Math.round(aat.percentage)
      })
    },
    disableAddAssetType() {
      return this.editObjectInputs.assetAssetTypes.length <= 1
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.requestFormOptions()
    },
  },
  async created() {
    this.editObjectInputs.assetGroupId = this.object.assetGroup.id

    this.requestFormOptions()
  },
}
</script>

<style scoped>
.section-label {
  @apply uppercase text-[14px] font-semibold text-darkGray;
}
.input-grid {
  @apply grid grid-cols-2 gap-x-10;
}
.input-grid > * {
  @apply mt-1;
}
</style>
