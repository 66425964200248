<template>
  <NotMemberLayer>
    <ScoreTableOverview
      class="w-full"
      :clusters="clustersTable"
      :results="clusterResults"
      v-if="isNormalAsset"
    />

    <div class="bg-white mt-6 w-full flex flex-col px-11 py-9" v-if="isNormalAsset">
      <div class="font-semibold pb-9">{{ $t('score.metadata-chartHeader') }}</div>
      <div class="flex justify-center font-semibold">
        <BarChart
          class="max-w-md"
          :labels="chartdata.labels"
          :datasets="chartdata.datasets"
          :yAxisLabel="$t('score.metadata-chartPoints')"
        />
      </div>
    </div>
    <div class="bg-white mt-6 w-full flex px-11 py-6 text-cyan font-semibold space-x-4">
      <div class="break-words">{{ $t('score.metadata-taxonomy-compliance') }}</div>
      <div class="break-words">
        {{
        $t(
        `score.metadata-taxonomy-${
        taxonomyScore === 100 ? 'compliant' : 'nonCompliant'
        }`,
        { taxonomyScore }
        )
        }}
      </div>
    </div>
    <div
      class="bg-white mt-6 w-full flex px-11 py-6 text-cyan font-semibold space-x-4"
      v-if="!isNormalAsset"
    >
      <div class="break-words">{{ $t('score.finance-score') }}</div>
      <div class="break-words">
        {{
        `${financeScore}%`
        }}
      </div>
    </div>
  </NotMemberLayer>
</template>

<script>
import { BarChart } from '@/components/charts'
import {
  ScoreTableOverview,
  NotMemberLayer,
} from '@/components/questionnaire/score'
import { user as userHelpers } from '@/helpers'
import { EUserRole } from '@/enums'
import { numeral } from '../../../helpers/general'

export default {
  components: {
    BarChart,
    ScoreTableOverview,
    NotMemberLayer,
  },
  props: {
    questionnaireId: {
      // id
      type: String,
      required: true,
    },
    clustersTable: {
      type: Object,
      required: true,
    },
    taxonomyScore: {
      type: Number,
      required: true,
    },
    financeScore: {
      type: Number,
    },
    isBuyerAsset: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    clusterResults() {
      return this.clustersTable?.map(c => c.weightedAchievedScoreOverall)
    },
    chartdata() {
      const numberOfClusters = process.env.VUE_APP_CLUSTER_COUNT
      return {
        labels: Array.from(Array(+numberOfClusters).keys()).map(n => {
          return `Cluster ${numeral(n + 1)}`
        }),
        datasets: [
          {
            label: this.$t('score.question-optionScore'),
            backgroundColor: 'primaryGradient',
            data: this.clusterResults,
          },
        ],
      }
    },
    isNormalAsset() {
      return !JSON.parse(this.isBuyerAsset)
    },
  },
  watch: {
    isNormalAsset: {
      handler: function (val) {
        if (!val) this.$emit('setToFullScreen')
      },
      immediate: true,
    },
  },
}
</script>
